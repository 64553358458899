export default class RedirectMusic {
    constructor() {
        this.init();
    }

    init() {
        this.redirectMusic();
    }

    //ティザーでmusicモーダルを開くパスを設定していたので、/music/に飛ぶように
    redirectMusic() {
        const url = new URL(window.location.href);

        const urlProtocol = url.protocol;
        const urlHost = url.host;
        const urlHash = url.hash;

        if (urlHash === '#music') {
            const musicpath = urlProtocol + '//' + urlHost + '/music/';
            window.open(musicpath, '_self');
        }
    }
}
