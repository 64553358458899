export default class VisualArchive {
    constructor() {
        this.init();
    }

    init() {
        this.showKv();
    }

    showKv() {
        const kvBtn = [...document.querySelectorAll('.js-kv')];
        const kvimg = document.querySelector('.js-kv-img');
        const kvttl = document.querySelector('.js-kv-ttl');
        const kvclose = document.querySelector('.js-kv-close');
        const modal = document.querySelector('.js-modal[data-modalid="modal_kv"]');

        kvBtn.forEach((el) => {
            el.addEventListener('click', () => {
                const img = el.querySelector('img');
                const type = el.getAttribute('data-type');
                const ttl = img.getAttribute('alt');
                const id = el.getAttribute('data-kv');
                kvttl.innerHTML = ttl;
                modal.setAttribute('data-kv', id);

                if (type === 'wide') {
                    modal.setAttribute('data-type', type);
                }
            });
        });

        kvclose.addEventListener('click', () => {
            kvimg.setAttribute('src', '');
            kvttl.innerHTML = '';
            modal.setAttribute('data-type', '');
            modal.setAttribute('data-kv', '');
        });
    }
}
