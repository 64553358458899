import { Config } from '../common/config/config';
import Storage from '../common/utils/storage.es6';

import VisualArchive from './visualarchive.es6';
import RedirectMusic from './redirectmusic.es6';
import Intro from './intro.es6';
import Banner from './banner.es6';
import Movie from './movie.es6';

export default class Main {
    constructor() {
        window.CONFIG = Config;
        window.STORAGE = new Storage();

        const visualarchive = new VisualArchive();
        const redirectmusic = new RedirectMusic();
        const intro = new Intro();
        const banner = new Banner();
        const movie = new Movie();
    }
}

const main = new Main();