export default class Storage {
    constructor() {
        // セッションストレージを取得
        this.data = JSON.parse(sessionStorage.getItem(CONFIG.SESSION_STORAGE.KEY) || JSON.stringify(CONFIG.SESSION_STORAGE.DATA));
    }

    set(prop, val) {
        if (prop && typeof val != 'undefined') {
            this.data[prop] = val;
        }

        // セッションストレージを保存
        sessionStorage.setItem(CONFIG.SESSION_STORAGE.KEY, JSON.stringify(this.data));
    }
}
